import { MoonStar, Sun } from 'lucide-react';
import { Theme, useTheme } from 'remix-themes';
import { SimpleTooltip } from './ui/tooltip';

export default function ThemeToggle() {
  const [theme, setTheme] = useTheme();

  return (
    <SimpleTooltip
      content={
        'Switch to ' + (theme === Theme.LIGHT ? 'Dark' : 'Light') + ' Mode'
      }
      position="middle"
    >
      <div className="flex relative w-[48px]">
        <div
          className="flex relative w-full h-fit border-[2px] border-[#1A2223] rounded-[21px] md:rounded-[24px] cursor-pointer items-center"
          onClick={() => {
            setTheme((theme) =>
              theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT
            );
          }}
          aria-label="toggle theme"
        >
          <span className="flex w-[50%] relative rounded-full -left-[1%] dark:translate-x-[103%] bg-[#1A2223] transition-all items-center justify-center p-1">
            <MoonStar className="w-full h-full hidden dark:block dark:text-white" />
            <Sun className="w-full h-full block dark:hidden text-white" />
          </span>
        </div>
      </div>
    </SimpleTooltip>
  );
}
