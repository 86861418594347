import { Link, useLoaderData } from '@remix-run/react';
import { useState } from 'react';
import { userData } from '~/auth/getUserData';
import { Chevron } from '~/components/icons/Chevron';
import { Person } from '~/components/icons/Person';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/popover';
import { useLogout } from '~/hooks/useLogout';
import { getAsset } from '~/lib/asset';
import { formatName } from '~/lib/utils';
import ThemeToggle from '../../theme-toggle';
import { Button } from '../../ui/button';
import { hoverData } from './constant';

export const Navbar = () => {
  const logout = useLogout();
  const data: {
    isLoggedIn: boolean;
    userData: userData;
    token: string;
    ok: boolean;
    registered: boolean;
  } = useLoaderData();

  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <nav className="fixed top-0 shadow-md p-4 px-5 sm:px-20 w-full bg-neutral-50 dark:bg-[#151515] z-50 border-b border-neutral-100 dark:border-neutral-900">
      <div className="flex gap-2 justify-between items-center">
        <Link className="flex items-center gap-4" to="/">
          {/* LOGO */}
          <div className="relative w-9 h-9 max-md:w-8 max-md:h-8 sm:hidden">
            <img
              src={getAsset('/brands/compfest-logo.png')}
              alt="logo"
              sizes=""
              className="object-contain"
            />
          </div>
          {/* TEXT LOGO */}
          <div className="relative w-44 max-md:w-40 max-sm:hidden">
            <img
              src={getAsset('/brands/logo-text-light.png')}
              alt="text logo"
              sizes=""
              className="object-contain dark:hidden"
            />
            <img
              src={getAsset('/brands/logo-text-dark.png')}
              alt="text logo"
              sizes=""
              className="object-contain hidden dark:block"
            />
          </div>
        </Link>
        <div className="flex gap-9 max-md:gap-7 max-sm:gap-4 items-center">
          <ThemeToggle />
          {data?.isLoggedIn ? (
            <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
              <PopoverTrigger asChild>
                <button className="relative group flex gap-3 max-sm:gap-2 py-2 items-center text-black dark:text-white fill-black dark:fill-white group">
                  <Person size="w-5 h-5" fill="fill-current" />
                  <p className="font-ubuntu font-bold text-base max-sm:hidden">
                    {formatName(data.userData.name)}
                  </p>
                  <Chevron
                    className={`${popoverOpen ? '-rotate-180' : ''} duration-300`}
                    size="w-6 h-6 max-md:w-5 max-md:h-5"
                    fill="fill-current"
                  />
                </button>
              </PopoverTrigger>
              <PopoverContent className="z-50">
                {hoverData.map((item, index) => (
                  <Link
                    key={index}
                    to={item.link}
                    className={`font-ubuntu font-medium text-sm w-full px-5 py-3 text-left hover:bg-[#E9E9E9] dark:hover:bg-neutral-900 duration-300 rounded-xl ${
                      item.isAdmin && !data.userData.role.includes('ADMIN')
                        ? 'hidden'
                        : 'block'
                    }`}
                  >
                    {item.title}
                  </Link>
                ))}
                <button
                  onClick={logout}
                  className="font-ubuntu font-medium text-sm w-full px-5 py-3 text-left hover:bg-[#E9E9E9] dark:hover:bg-neutral-900 duration-300 rounded-xl"
                >
                  Sign Out
                </button>
              </PopoverContent>
            </Popover>
          ) : (
            !data?.isLoggedIn && (
              <form method="POST" action="/api/login">
                <Button variant="primary" type="submit">
                  Login
                </Button>
              </form>
            )
          )}
        </div>
      </div>
    </nav>
  );
};
