export const hoverData: { title: string; link: string; isAdmin?: boolean }[] = [
  {
    title: 'Dashboard',
    link: '/dashboard',
  },
  {
    title: 'Take a Test',
    link: '/take-a-test',
  },
];
