import { LoaderFunctionArgs, redirect } from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import { Home } from 'lucide-react';
import { getUserData } from '~/auth/getUserData';
import { isTokenValid, tokenCookie } from '~/auth/token';
import Footer from '~/components/elements/Footer';
import { Navbar } from '~/components/elements/Navbar';
import { Button } from '~/components/ui/button';
import Image from '~/components/ui/image';
import { Toaster } from '~/components/ui/toaster';

const unprotectedRoutes = ['/'];

export async function loader({ request }: LoaderFunctionArgs) {
  const cookieHeader = request.headers.get('Cookie');
  const token = await tokenCookie.parse(cookieHeader);

  const url = new URL(request.url);
  const isAuthPage = unprotectedRoutes.includes(url.pathname);

  // Check if currrent page is not login page and token is invalid
  if (!isAuthPage && !(await isTokenValid(token))) {
    return redirect('/', {});
  }

  return {
    isLoggedIn: await isTokenValid(token),
    userData: await getUserData(token),
  };
}

export function ErrorBoundary() {
  return (
    <main className="bg-component-light-frame text-black dark:bg-component-dark-frame dark:text-white">
      {/* <Navbar /> */}
      <main className="pt-20 font-ubuntumax-w-[1440px] mx-auto min-h-screen border-x-[2px] border-component-light-border dark:border-component-dark-border">
        <div className="h-screen flex items-center justify-center flex-col gap-4">
          <Image src="./Jupiter.png" alt="Jupiter" className="w-[200px]" />

          <h1 className="text-3xl font-tilt-warp">Terjadi Kesalahan!</h1>
          <p className="font-ubuntu">
            Try refreshing the page, or go back to the home page by clicking
            this button.
          </p>
          <a href="/">
            <Button>
              <Home />
              Home
            </Button>
          </a>
        </div>
        <Footer />
      </main>
    </main>
  );
}

export default function Index() {
  return (
    <main className="bg-component-light-frame text-black dark:bg-component-dark-frame dark:text-white">
      <Navbar />
      <main className="pt-16 font-ubuntu max-w-[1920px] mx-auto min-h-screen border-x-[2px] border-component-light-border dark:border-component-dark-border overflow-x-hidden">
        <Outlet />
        <Toaster />
        <Footer />
      </main>
    </main>
  );
}
