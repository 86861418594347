import React from 'react';
import { IconProps } from './interface';

export const Person: React.FC<IconProps> = ({
  fill = 'fill-[#000]',
  className,
  size,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} ${size}`}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.83346 2.99999C11.1168 2.99999 12.1668 4.04999 12.1668 5.33332C12.1668 6.61666 11.1168 7.66666 9.83346 7.66666C8.55012 7.66666 7.50012 6.61666 7.50012 5.33332C7.50012 4.04999 8.55012 2.99999 9.83346 2.99999ZM9.83346 14.6667C12.9835 14.6667 16.6001 16.1717 16.8335 17H2.83346C3.10179 16.16 6.69512 14.6667 9.83346 14.6667ZM9.83346 0.666656C7.25512 0.666656 5.16679 2.75499 5.16679 5.33332C5.16679 7.91166 7.25512 9.99999 9.83346 9.99999C12.4118 9.99999 14.5001 7.91166 14.5001 5.33332C14.5001 2.75499 12.4118 0.666656 9.83346 0.666656ZM9.83346 12.3333C6.71846 12.3333 0.500122 13.8967 0.500122 17V19.3333H19.1668V17C19.1668 13.8967 12.9485 12.3333 9.83346 12.3333Z"
        className={fill}
      />
    </svg>
  );
};
